import { RadiographyEvidenceItem } from './report-types'
import { ReportTable } from './report-table'
import { getConsoleConfig, getRadiationExposureConfig, getSettingsConfig, getTubeConfig } from './config'
import { PreviousNextNavigation } from './previous-next-navigation'
import { ReportPageCount } from './report-page-count'
import { UserNameAndEmail } from './user-name-and-email'
import { useState } from 'react'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRequest } from '../../../hooks/use-request'
import { useParams, useSearchParams } from 'react-router-dom'
import { AnalysisStatus } from './analysis-status'
import { AnalysisForm } from './analysis-form'
import { AnalysisState } from '../../../components/form-fields/lookup'
import { useUser } from '../../../auth'
import { useLocalTranslation } from '../../../hooks'
import { useIsReportOwner } from '../../../components/breadcrumbs/is-report-owner-context'

type RadiographyReportProps = {
  evidence: Array<RadiographyEvidenceItem>
  isLti: boolean
}

export function RadiographyReport({ evidence, isLti }: RadiographyReportProps) {
  const { reportId } = useParams()
  const { client } = useRequest()
  const [user, _] = useUser()
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined)
  const [currentEvidenceIndex, setCurrentEvidenceIndex] = useState(0)
  const [isEditingAnalysis, setIsEditingAnalysis] = useState(false)
  const totalPages = evidence?.length ?? 0
  const reportData = evidence?.[currentEvidenceIndex] ?? {}
  const evidenceData = reportData.evidenceData
  const isEvidenceOwner = user?.email.toLowerCase() === reportData.userEmail.toLowerCase()
  const { setIsReportOwner } = useIsReportOwner()
  const t = useLocalTranslation()

  useQuery({
    queryKey: [reportId, evidence?.[currentEvidenceIndex]?.id],
    queryFn: async () => {
      const response = client.get(`/api/v1/report/${reportId}/image/${evidence?.[currentEvidenceIndex]?.id}`)
      setImageUrl((await response).data['url'])
      return response
    },
  })

  useEffect(() => {
    // Update context when the report page is loaded
    const isOwner = user?.email.toLowerCase() === reportData.userEmail.toLowerCase()
    setIsReportOwner(isOwner)

    // Reset context when the component unmounts
    return () => setIsReportOwner(null)
  }, [user, reportData, setIsReportOwner])

  function onClickPrevious() {
    if (currentEvidenceIndex > 0) {
      setCurrentEvidenceIndex(currentEvidenceIndex - 1)
      setIsEditingAnalysis(false)
    }
  }
  function onClickNext() {
    if (currentEvidenceIndex < evidence?.length - 1) {
      setCurrentEvidenceIndex(currentEvidenceIndex + 1)
      setIsEditingAnalysis(false)
    }
  }

  function onClientAcceptReject() {
    setIsEditingAnalysis(true)
  }

  return (
    <div className='grid grid-cols-1 lg:grid-cols-7 gap-6 grid-flow-row-dense'>
      <div className='flex flex-col lg:col-span-3'>
        <img src={imageUrl} alt={`Image of ${reportData.practiceActivity}`} className='object-contain flex-none' />
        <div className='flex flex-col w-full py-2 gap-2'>
          {(!isEditingAnalysis || !isEvidenceOwner) && (
            <div className='flex flex-row w-full h-10 justify-center'>
              {((!isEditingAnalysis && reportData.analysisStatus.id != AnalysisState.NotAnalysed) ||
                !isEvidenceOwner) && <AnalysisStatus statusCode={reportData.analysisStatus.id} />}
              {reportData.analysisStatus.id == AnalysisState.NotAnalysed && isEvidenceOwner && (
                <button
                  type='button'
                  className='max-w-80 bg-blue-600 text-white rounded-md py-2 px-6'
                  onClick={onClientAcceptReject}
                >
                  {t('report.analysisButton')}
                </button>
              )}
            </div>
          )}
          {!(reportData.analysisStatus.id == AnalysisState.NotAnalysed && !isEditingAnalysis) && (
            // https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
            <AnalysisForm
              analysis={reportData.analysis}
              reportId={reportId ?? 'undefined'}
              evidenceId={reportData.id.toString()}
              analysisStatus={reportData.analysisStatus}
              isEditing={isEditingAnalysis}
              isEditingAllowed={isEvidenceOwner}
              key={reportData.id}
              changeEditingState={(isEditing: boolean) => setIsEditingAnalysis(isEditing)}
              isLtiLaunch={isLti}
            />
          )}
        </div>
      </div>
      <div className='flex flex-col gap-4 col-span-3 lg:col-span-4'>
        <ReportPageCount
          localizationId={'report.imageNumber'}
          currentPageIndex={currentEvidenceIndex}
          totalPages={totalPages}
        />
        <div className='grid gap-6 grid-flow-col'>
          <div className='grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2'>
            {totalPages > 1 && (
              <PreviousNextNavigation
                className='grid grid-flow-col gap-2 justify-center max-h-12'
                isFirstPage={currentEvidenceIndex === 0}
                isLastPage={currentEvidenceIndex === evidence?.length - 1}
                onClickPrevious={onClickPrevious}
                onClickNext={onClickNext}
              />
            )}
            <UserNameAndEmail userEmail={reportData.userEmail} userName={reportData.userName} />
          </div>
        </div>
        {/* grid-cols-fill-230 is a custom style set in tailwind.config.js */}
        <div className='grid gap-6 lg:grid-cols-fill-230'>
          <ReportTable data={getSettingsConfig(evidenceData)} tableTitle='settings' />
          <ReportTable data={getConsoleConfig(evidenceData)} tableTitle='console' />
          <ReportTable data={getTubeConfig(evidenceData)} tableTitle='tube' />
          <ReportTable data={getRadiationExposureConfig(evidenceData)} tableTitle='radiationExposure' />
        </div>
      </div>
    </div>
  )
}
