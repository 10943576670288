import { matchPath, useParams, Params, useLocation } from 'react-router-dom'
import { Crumb, CrumbProps } from './crumb'
import { Match } from './index'
import { LookupFieldOption } from '../form-fields/lookup'
import { useIsReportOwner } from './is-report-owner-context'

interface GenericBreadcrumbsProps {
  matches: Match[]
  pathname: string
}

export function GenericBreadcrumbs({ matches, pathname }: GenericBreadcrumbsProps) {
  let isVisibleOverride = false
  const params = useParams()
  const { state } = useLocation()
  const { isReportOwner } = useIsReportOwner()

  const getDynamicName = (match: Match, params: Readonly<Params<string>>) => {
    const paramName = match.handle?.breadcrumb?.paramName

    return !!paramName
      ? // using as string to indicate that the lookup will always return a matching value
        (match.data?.data?.find((lookupField: LookupFieldOption) => params[paramName] == lookupField.id)
          ?.description as string)
      : match.handle.breadcrumb.defaultName
  }

  const parseMatch = (match: Match): Omit<CrumbProps, 'index'> => {
    return !match.handle.breadcrumb.isParamRoute
      ? {
          name: match.handle.breadcrumb.defaultName,
          pathname: match.handle.breadcrumb.pathnameOveride ?? match.pathname,
        }
      : {
          name: getDynamicName(match, params),
          pathname: match.handle.breadcrumb.pathnameOveride ?? match.pathname,
        }
  }

  const breadcrumbs = matches.filter((route) => {
    const breadcrumb = route.handle?.breadcrumb
    isVisibleOverride = !!breadcrumb?.alwaysVisible
    return !!breadcrumb && (isVisibleOverride || !Boolean(matchPath(route.pathname, pathname)))
  })

  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol role='list' className='flex items-center space-x-4'>
        {(breadcrumbs.length > 1 || isVisibleOverride) &&
          breadcrumbs.map((match, index) => {
            // Dont display breadcrumbs for evidence sub routes
            const isEvidenceSubPath = match.pathname.includes(`/report/${params.reportId}/evidence/`)
            if (isEvidenceSubPath) {
              return null
            }

            const isReportRoute = !!matchPath(match.pathname, `/report/${params.reportId}`)
            const { name, pathname } = parseMatch(match)

            // If it's a report route, modify the breadcrumb name based on isEvidenceOwner
            // This allows the breadcrumb to display "Your Reports" or "Learner Reports" based on the user
            const finalBreadcrumbName = isReportRoute
              ? isReportOwner
                ? 'report.yourReports'
                : 'report.learnerReports'
              : name

            const finalBreadcrumbPathname = isReportRoute ? (isReportOwner ? '/report' : '/report/learner') : pathname

            return isReportRoute ? (
              <Crumb
                key={`${index}-${state?.reportPath || pathname}`}
                index={index}
                name={state?.name || finalBreadcrumbName}
                pathname={state?.reportPath || finalBreadcrumbPathname}
              />
            ) : (
              <Crumb key={`${index}-${pathname}`} index={index} name={name} pathname={pathname} />
            )
          })}
      </ol>
    </nav>
  )
}
