import { createContext } from 'react'
import { createUseAuth, createUseUser } from './hooks'
import { AuthMethod } from './methods'
import { createAuthProvider } from './provider'
import { AuthContext, WhoAmI } from './types'

export * from './hooks'
export * from './methods'
export * from './provider'
export * from './types'

export const createAuthentication = <T>(methods: [AuthMethod, ...AuthMethod[]], whoAmI: WhoAmI<T>) => {
  const AuthContext = createContext<AuthContext<T> | null>(null)
  const AuthProvider = createAuthProvider(AuthContext, methods, whoAmI)
  const useAuth = createUseAuth(AuthContext)
  const useUser = createUseUser(useAuth)
  return {
    AuthContext,
    AuthProvider,
    useAuth,
    useUser,
  }
}
