import React, { createContext, useContext, useState } from 'react'

interface IsReportOwnerContextType {
  isReportOwner: boolean | null // Use `null` to indicate no context value is set
  setIsReportOwner: (value: boolean | null) => void
}

const noProvider = () => {
  console.warn('setIsReportOwner called without a provider.')
}

const IsReportOwnerContext = createContext<IsReportOwnerContextType>({
  isReportOwner: null,
  setIsReportOwner: noProvider,
})

export const IsReportOwnerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isReportOwner, setIsReportOwner] = useState<boolean | null>(null)

  return (
    <IsReportOwnerContext.Provider value={{ isReportOwner, setIsReportOwner }}>
      {children}
    </IsReportOwnerContext.Provider>
  )
}

export const useIsReportOwner = () => {
  return useContext(IsReportOwnerContext)
}
